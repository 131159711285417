<template>
  <div class="dashboard-content">
    <div class="row">
       <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h6 class="mb-2" style="text-transform:uppercase">
             Sponsor Ad - Purchase/Edit - Zip Code Of Added Locations
            </h6>
            <hr>
            <div class="row">
                <div class="col-md-8 table-responsive">
                <form action="">
                        <div class="form-check">
                            <input :checked="total_locations > 0" :disabled="total_locations <= 0" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label " for="flexRadioDefault1" style="font-size:17px">
                            $5 Weekly, Purchase Your ZIP Code
                            </label>
                        </div>
                        <div class="form-check">
                            <input :disabled="total_locations > 0" :checked="total_locations <= 0" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" >
                            <label class="form-check-label" for="flexRadioDefault2" style="font-size:17px">
                                You Have Already Purchased The ZIP Code Of Each Added Location 
                            </label>
                        </div>
                </form>
                </div>
            </div>
            <div class="row">
                <div class=col-md-6>
                    <router-link to="/select-ad" class="btn btn-success mt-3"><i class="fas fa-arrow-left"></i> Back</router-link>
                </div>
                <div class=col-md-6>
                    <router-link to="/create-child-ad"  class="btn btn-primary mt-3" style="float:right"> Next <i class="fas fa-arrow-right"></i></router-link>

                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../../../services/http-common';
export default {
  data(){
    return {
      total_locations :'',
    }
  },
    mounted(){
    return axios
        .get(`${process.env.VUE_APP_API_URL}total_locations`)
        .then(response => {
          this.total_locations = response.data;
      }).catch(error => {
        this.$toast.error('Something Went Wrong!', {
              position: "bottom-right",
              timeout: 2508,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
              top:'300px',
            });
    });
  }
}
</script>
<style scoped>
.dashboard-list-box ul li{
    padding: 13px 30px !important;
}
.uppercase{
    text-transform: uppercase !important;
}
</style>
